@import '~antd/dist/antd.css';

#root {
  height: 100%;
  background-color: #f0f2f5;
}

body {
  background-color: #f0f2f5;
}

.ant-spin-container {
  height: 100%;
}

.action-text {
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
}

.action-red {
  color: #f5222d;
}

.action-text:hover {
  opacity: 0.8;
}

.pre-wrap {
  white-space: pre-wrap;
}

.markdown {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  width: 100%;
  margin: 8px 0 16px;
}

.markdown {
  color: #314659;
  font-size: 14px;
  line-height: 2;
}
.highlight {
  line-height: 1.5;
}
.markdown img {
  max-width: calc(100% - 32px);
}
.markdown p > img {
  margin: 34px 0;
  -webkit-box-shadow: 0 8px 20px rgba(143, 168, 191, 0.35);
  box-shadow: 0 8px 20px rgba(143, 168, 191, 0.35);
}
.markdown h1 {
  color: #0d1a26;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 8px;
  font-family: Avenir, 'Chinese Quote', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 30px;
  font-variant: tabular-nums;
  line-height: 38px;
}
.markdown h1 .subtitle {
  margin-left: 12px;
}
.markdown h2 {
  font-size: 24px;
  line-height: 32px;
}
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  color: #0d1a26;
  font-family: Avenir, 'Chinese Quote', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  margin: 1.6em 0 0.6em;
  font-weight: 500;
  clear: both;
}
.markdown h3 {
  font-size: 18px;
}
.markdown h4 {
  font-size: 16px;
}
.markdown h5 {
  font-size: 14px;
}
.markdown h6 {
  font-size: 12px;
}
.markdown hr {
  height: 1px;
  border: 0;
  background: #ebedf0;
  margin: 56px 0;
  clear: both;
}
.markdown p,
.markdown pre {
  margin: 1em 0;
}
.markdown ul > li {
  list-style-type: circle;
  margin-left: 20px;
  padding-left: 4px;
}
.markdown ul > li:empty {
  display: none;
}
.markdown ol > li {
  list-style-type: decimal;
  margin-left: 20px;
  padding-left: 4px;
}
.markdown ul > li > p,
.markdown ol > li > p {
  margin: 0.2em 0;
}
.markdown code {
  margin: 0 1px;
  background: #f2f4f5;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.9em;
  border: 1px solid #eee;
}
.markdown pre {
  border-radius: 2px;
  background: #f2f4f5;
  font-family: 'Lucida Console', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono',
    monospace;
}
.markdown pre code {
  border: none;
  background: #f2f4f5;
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: #314659;
  overflow: auto;
}
.markdown strong,
.markdown b {
  font-weight: 500;
}
.markdown > table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #ebedf0;
  width: 100%;
  margin: 8px 0 16px;
}
.markdown > table th {
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 500;
  background: rgba(0, 0, 0, 0.02);
}
.markdown > table th,
.markdown > table td {
  border: 1px solid #ebedf0;
  padding: 16px 24px;
  text-align: left;
}
